export function toLP() {
  window.gtag("event", "conversion", { send_to: "AW-743269490/rF33CMzYiKcBEPLIteIC" })
}
export function gtagReportConversion(url) {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  window.gtag('event', 'conversion', {
    'send_to': 'AW-743269490/udRcCIDRiKcBEPLIteIC',
    'event_callback': callback
  });
  window.gtag('event', 'Submit', {
    'send_to': 'UA-44232253-17',
    'event_category': 'Download'
  });
  return false
}
export default { toLP, gtagReportConversion }

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import classNames from 'classnames'
import logo from '../images/logo.svg'
import { toLP } from '../helpers/gtagHelper'
import ReactPixel from 'react-facebook-pixel'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.state = {
      slideIn: false,
      isShow: false
    }
  }
  componentDidMount() {
    const path = window.location.pathname
    if(path === '/') {
      setTimeout(() => {this.setState({slideIn: true})}, 1000)
    } else {
      this.setState({slideIn: true})
    }
    ReactPixel.init('607255283134245')
    ReactPixel.track('PageView')
  }
  handleClick() {
    this.setState({isShow: !this.state.isShow})
  }
  render() {
    const headerClass = classNames('global-header', 'js_header_slide', {
      'js_header_slide_in': this.state.slideIn
    })
    const navbarClass = classNames('collapse navbar-collapse', {
      'show': this.state.isShow
    })
    return (
      <div>
        <div className={headerClass}>
          <nav className="navbar navbar-expand-lg navbar-lightx">
            <Link className="global-header__logo navbar-brand" to="/my-logue">
              <p className="black">AI・チャットボットの情報発信メディア</p>
              <img alt="マイローグロゴ" src={logo} />
            </Link>
            <button aria-controls="nteavbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" className="navbar-toggler"
              data-target="#navbarSupportedContent" data-toggle="collapse" type="button" onClick={this.handleClick}>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={navbarClass} id="navbarSupportedContent">
              <ul className="navbar-nav align-items-center ml-auto">
                <li className="nav-item"><Link className="btn-link black" to="/my-logue/about">My-logueとは</Link></li>
                <li className="nav-item"><Link className="btn-link black" to="/my-logue/#popular">人気記事</Link></li>
                <li className="nav-item"><Link className="btn-link black" to="/my-logue/category/case-study">AIチャットボット導入事例</Link></li>
                <li className="nav-item">
                  <a className="btn btn-primary stretch" href="https://www.my-ope.net" target="blank" onClick={toLP}>
                    <small>社内問い合わせ専用AIチャットボット
                      <br/>My-ope office サービス紹介
                    </small>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="d-none d-md-block" style={{backgroundColor:"#E8ECF2", height:"80px"}}></div>
        <div className="d-md-none" style={{backgroundColor:"#E8ECF2", height:"58px"}}></div>
      </div>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

import React from 'react'
import { Link } from 'gatsby'
import { toLP } from '../helpers/gtagHelper'

const Footer = () => (
  <div className="global-footer">
    <div className="global-footer__wrap d-md-flex justify-content-between align-items-center">
      <ul className="global-footer__links d-flex align-items-center">
      <li className="global-footer__item"><Link className="btn-link" to="/my-logue">トップ</Link></li>
      <li className="global-footer__item"><Link className="btn-link" to="/my-logue/about">My-logueとは</Link></li>
      <li className="global-footer__item"><Link className="btn-link" to="/my-logue/doc_request">無料資料ダウンロード</Link></li>
      <li className="global-footer__item"><a className="btn-link" href="https://www.my-ope.net" target="blank" onClick={toLP}>My-ope office</a></li>
      </ul>
      <div className="global-footer__company">
        <p>運営会社</p><span><a href="https://www.mof-mof.co.jp/" target="blank">株式会社mofmof</a></span>
      </div>
    </div>
    <div className="global-footer__copyright"><small>Copyright©My-logue All rights reserved.</small></div>
  </div>
)

export default Footer

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import classNames from 'classnames'
import isEmpty from "is-empty";
import ReactPixel from 'react-facebook-pixel'
import apigClientFactory from "aws-api-gateway-client"
import Layout from '../components/layout'
import { gtagReportConversion } from '../helpers/gtagHelper'

const EMAIL_VALIDATION = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/

const apigClient = apigClientFactory.newClient({
  invokeUrl: "https://bd3u2d07zb.execute-api.ap-northeast-1.amazonaws.com/prod",
  apiKey: 'Ug9LWTgRbS7UQNo5ExQfp2temRsp13pm4KgEKo0p'
});

function invokeAPI({
  company,
  division,
  name,
  email,
  tel,
  memo,
  document,
  visitRequest,
}) {
  const params = {};
  const pathTemplate = '/myope-lp-contact-form';
  const method = 'POST';
  const additionalParams = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  const body = {
    caller: "My-logue",
    subject: "My-logue 資料ダウンロードされました",
    mailBody:`
会社名: ${company}

部署名: ${isEmpty(division) ? "[無回答]" : division}

お名前: ${name}

メールアドレス: ${email}

電話番号: ${isEmpty(tel) ? "[無回答]" : tel}

備考: ${memo}

ダウンロードされた資料: ${document}

訪問提案の希望: ${visitRequest ? "あり" : "なし"}

`,
    contact: {
      companyName: company,
      divisionName: isEmpty(division) ? "[無回答]" : division,
      name: name,
      email: email,
      tel: isEmpty(tel) ? "[無回答]" : `"${tel}"`,
      memo: memo
    }
  }

  return apigClient.invokeApi(params, pathTemplate, method, additionalParams, body);
}

class DocRequest extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      isSelected: false,
      isSending: false,
      document: { value: "", error: null },
      company: { value: "", error: null },
      division: { value: "" },
      name: { value: "", error: null },
      email: { value: "", error: null },
      tel: { value: "" },
      memo: { value: "" },
      visitRequest: { value: false },
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: {value: value}
    })
  }

  onBlur = (e) => {
    this.handleErrMsgs(e.target.name)
  }

  handleErrMsgs(item) {
    const { document, company, name, email } = this.state
    if (item === 'document') {
      if (document.value) {
        this.setState({ document: { value: document.value, error: null } })
      } else {
        this.setState({ document: { value: document.value, error: 'ご希望の資料を選択してください' } })
      }
    }
    if (item === 'company') {
      if (company.value) {
        this.setState({ company: { value: company.value, error: null } })
      } else {
        this.setState({ company: { value: company.value, error: '会社名を入力してください' } })
      }
    }
    if (item === 'name') {
      if (name.value) {
        this.setState({ name: { value: name.value, error: "" } })
      } else {
        this.setState({ name: { value: name.value, error: '氏名を入力してください' } })
      }
    }

    if (item === 'email') {
      if (!email.value) {
        this.setState({ email: { value: email.value, error: 'メールアドレスを入力してください' } })
      } else if (!email.value.match(EMAIL_VALIDATION)) {
        this.setState({ email: { value: email.value, error: '正しいメールアドレスを入力してください' } })
      } else {
        this.setState({ email: { value: email.value, error: "" } })
      }
    }
  }

  render() {
    return (
    <StaticQuery
      query={graphql`
        query {
          checkListImage:file(relativePath: {eq: "checklist.jpg"}) {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          checkList:file(relativePath: {eq: "社内AIチャットボット導入に失敗しないための100のチェックリスト.pdf"}) {
            name
            id
            publicURL
          }
          collectionSheetImage:file(relativePath: {eq: "collectionsheet.jpg"}) {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          collectionSheet:file(relativePath: {eq: "社内AIチャットボット導入準備用Q&A収集シート.xlsx"}) {
            name
            id
            publicURL
          }
        }
      `}
      render={data => {
        const formSelectClass = classNames('form__select', {
          selected: this.state.isSelected
        })
        const { document, company, division, name, email, tel, memo, visitRequest, isSending } = this.state
        const handleSubmit = (e) => {
          const isCompletedRequired = document.value && company.value && name.value && email.value
          const isValid = !document.error && !company.error && !name.error && !email.error
          this.setState({isSending: true})

          if (isCompletedRequired && isValid) {
            invokeAPI({
              company: company.value,
              divisiton: division.value,
              name: name.value,
              email: email.value,
              tel: tel.value,
              memo: memo.value,
              document: document.value,
              visitRequest: visitRequest.value,
            }).then(() => {
              if (window.location.hostname !== "localhost") {
                ReactPixel.init('607255283134245')
                ReactPixel.track('Contact')
                gtagReportConversion()
              }
              window.alert('資料ダウンロードありがとうございます。\nダウンロードページに移動します。')
              window.location = window.location.hostname === "localhost"
                ? `/download/${data.checkList.id}`
                : `/my-logue/download/${data.checkList.id}`
            }).catch(() => {
              window.alert("エラー：申し訳ありません。資料請求を送信できませんでした。");
              this.setState({isSending: false})
            })
          } else {
            this.handleErrMsgs('document')
            this.handleErrMsgs('company')
            this.handleErrMsgs('name')
            this.handleErrMsgs('email')
            window.alert('入力に不備があります。再度ご確認をお願いします。')
            this.setState({isSending: false})
            e.preventDefault()
          }
        }

        const errorMsg = (item) => {
          return (
            <span style={{color: 'red'}}>
              {this.state[item].error}
            </span>
          )
        }
        const errorStyle = (item) => this.state[item].error ? {borderColor: 'red'} : {}
        // const documentId = (documentName) => {
        //   if (documentName === "社内AIチャットボット導入に失敗しないための100のチェックリスト") {
        //     return data.checkList.id
        //   } else if (documentName === "社内AIチャットボット導入準備用Q&A収集シート") {
        //     return data.collectionSheet.id
        //   }
        // }

        return (
          <Layout>
            <main className="main">
              <section className="section bg-light-gray">
                <div className="container">
                  <div className="heading-top">
                    <h3>DOCUMENT</h3>
                    <p>無料資料ダウンロードのご案内</p><span>こちらの資料をご用意しております。</span>
                  </div>
                  <div className="list">
                    <ul className="d-md-flex justify-content-around">
                      <li className="list__item">
                        <div className="document">
                          <div className="document__wrap">
                            <div className="document__title">社内AIチャットボット<br />導入に失敗しないための100のチェックリスト</div>
                            <div className="document__img" style={{backgroundImage:`url(${data.checkListImage.childImageSharp.fluid.src})`}}></div>
                          </div>
                        </div>
                        <div className="list__description">100のチェックリストはAIチャットボットの社内導入をご検討の際にご活用頂けます。導入検討にあたって、大きく3つのステップにわけることができます。それぞれのステップで失敗しないためのチェックリストとしてご活用下さい。</div>
                      </li>
                      <li className="list__item">
                        <div className="document">
                          <div className="document__wrap">
                            <div className="document__title">社内AIチャットボット<br />導入準備用Q&A収集シート</div>
                            <div className="document__img" style={{backgroundImage:`url(${data.collectionSheetImage.childImageSharp.fluid.src})`}}></div>
                          </div>
                        </div>
                        <div className="list__description">Q&A収集シートはAIチャットボットの社内導入の準備の際にご活用頂けます。導入決定後から実際に社内リリースするまでの工程で効率よくQ&Aを収集するためのフォーマットとしてご活用下さい。</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
              <section className="section">
                <div className="container">
                  <div className="heading-top">
                    <h3>FORM</h3>
                    <p>無料資料ダウンロードフォーム</p><span>情報を入力して送信していただくと、ダウンロードリンクが表示されます。</span>
                  </div>
                  {/*
                    NOTE: 新規流入停止中
                    https://www.pivotaltracker.com/story/show/181292782
                  */}
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '240px', background: '#eee' }}>
                    <p style={{ fontSize: '1.8rem' }}>ただいま販売を一時停止させて頂いております。</p>
                  </div>
                  {/* <div className="form">
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="form__group">
                      <label className="form__label require">ご希望の資料</label>
                      <div className={formSelectClass}>
                        <select
                          className="doc_select"
                          name="document"
                          required="required"
                          value={document.value}
                          onChange={this.handleChange}
                          style={errorStyle('document')}
                          onBlur={this.onBlur}
                        >
                          <option value="">ご希望の資料を選択してください</option>
                          <option value="社内AIチャットボット導入に失敗しないための100のチェックリスト">社内AIチャットボット導入に失敗しないための100のチェックリスト</option>
                          <option value="社内AIチャットボット導入準備用Q&A収集シート">社内AIチャットボット導入準備用Q&A収集シート</option>
                        </select>
                      </div>
                      {errorMsg('document')}
                    </div>
                    <div className="form__group">
                      <label className="form__label require">会社名
                        <input
                          name="company"
                          placeholder="会社名をご記入ください"
                          type="text"
                          value={company.value}
                          onChange={this.handleChange}
                          required="required"
                          style={errorStyle('company')}
                          onBlur={this.onBlur}
                        />
                      </label>
                      {errorMsg('company')}
                    </div>
                    <div className="form__group">
                      <label className="form__label">部署名
                        <input
                          name="division"
                          placeholder="部署名をご記入ください"
                          type="text"
                          value={division.value}
                          onChange={this.handleChange}
                        />
                      </label>
                    </div>
                    <div className="form__group">
                      <label className="form__label require">氏名
                        <input
                          name="name"
                          placeholder="田中　太郎"
                          type="text"
                          required="required"
                          value={name.value}
                          onChange={this.handleChange}
                          style={errorStyle('name')}
                          onBlur={this.onBlur}
                        />
                      </label>
                      {errorMsg('name')}
                    </div>
                    <div className="form__group">
                      <label className="form__label require">メールアドレス
                        <input
                          name="email"
                          placeholder="example@example.com"
                          required="required"
                          type="email"
                          value={email.value}
                          onChange={this.handleChange}
                          style={errorStyle('email')}
                          onBlur={this.onBlur}
                        />
                      </label>
                      {errorMsg('email')}
                    </div>
                    <div className="form__group">
                      <label className="form__label">電話番号
                        <input
                          name="tel"
                          placeholder="03-1234-5678"
                          type="tel"
                          value={tel.value}
                          onChange={this.handleChange}
                        />
                      </label>
                    </div>
                    <div className="form__group">
                      <label className="form__label">備考
                        <textarea
                          name="memo"
                          placeholder="お問い合わせ内容をご記入ください"
                          rows="4"
                          value={memo.value}
                          onChange={this.handleChange}
                        />
                      </label>
                    </div>
                    <div className="form__group">
                      <input
                        name="visitRequest"
                        type="checkbox"
                        id="visit-request-checkbox"
                        value={visitRequest}
                        onChange={this.handleChange}
                      />
                      <label className="form__label" htmlFor="visit-request-checkbox">社内AIチャットボット My-ope officeの訪問提案を希望する<br/><span className="note">※遠方の場合はビデオ会議となります</span></label>
                    </div>
                    <div className="form__group text-center mt-5">
                      <button
                        className="btn-primary"
                        onClick={handleSubmit}
                        style={{maxWidth: "225px"}}
                        disabled={isSending}
                      >
                        {this.state.isSending ? "送信中" : "資料をダウンロードする"}
                      </button>
                    </div>
                  </div> */}
                </div>
              </section>
            </main>
          </Layout>
        )
      }}
    />
    )
  }
}

export default DocRequest
